import React, { useState } from 'react'
import SchoolInfosNavigation from '../../../components/Infos/SchoolInfos/SchoolInfosNavigation'
import SchoolLayout from '../../../components/schoollayout'
import BigButton from '../../../components/Ui/BigButton'

const AnalyseSecondaire = () => (
    <div>
        <h3 className='text-center !no-underline !text-[32px]'>Exemple d’activités pour le secondaire et cégep</h3>
        <ul>
            <li>Dictée avec les paroles d’une chanson</li>
            <li>Proposition d’articles sur l’artiste et rédaction d’un texte d’analyse ou d’opinion</li>
            <li>Discussion en classe autour de la conférence donnée par l’artiste</li>
            <li>Approfondissement d’une chanson de l’artiste, voir question ci-bas:</li>
        </ul>

        <h3>Analyse du Thèmes et Style</h3>
        <ul>
            <li>Quel est, selon toi, le thème ou les thèmes de la chanson? (l'amour, la liberté, la révolte, etc.) ① ② ⓒ</li>
            <li>Y-a t’il un message que l’artiste tente de transmettre au travers la chanson? ① ② ⓒ</li>
            <li>Y a-t-il des jeux de mots, des métaphores ou des comparaisons ? Donne un exemple de figure de style dans la chanson? ② ⓒ</li>
        </ul>

        <h3>Analyse structurelle</h3>
        <ul>
            <li>Décrit la structure de la chanson en donnant l’ordre dans lequel sont disposés les couplets, les refrains et le pont?  ① ② ⓒ</li>
            <li>Est-ce qu’il y a une introduction musicale ou une conclusion musicale ? Si oui comment le passage contribue à la chanson? ① ② ⓒ</li>
        </ul>

        <h3>Analyse musicale</h3>
        <ul>
            <li>Quels instruments sont utilisés dans la chanson? ① ② ⓒ</li>
            <li>Y a-t-il des solos instrumentaux ou des moments où certains instruments sont mis en avant? ① ② ⓒ</li>
            <li>Comment est le tempo de la chanson (lent, modéré ou rapide)? ① ② ⓒ</li>
            <li>Quel est le style musical de la chanson? ① ② ⓒ</li>
            <li>Ex: Pop, country, rock, etc.?</li>
            <li>Est-ce que la musique reflète ce que disent les paroles? ① ② ⓒ</li>
            <li>À quel autre artiste cette chanson te fait-elle penser? ① ② ⓒ</li>
        </ul>

        <h3>Suite au spectacle</h3>
        <ul>
            <li>Qu’as-tu retenu de ta rencontre avec l’artiste? ① ② ⓒ</li>
            <li>Aimerais-tu faire carrière dans le domaine artistique? Pourquoi? ① ② ⓒ</li>
            <li>Serais-tu capable de performer devant une foule? ① ② ⓒ</li>
            <li>Si tu avais à écrire une chanson, quel sujet ou enjeu social t'inspirerait le plus? ① ② ⓒ</li>
        </ul>

        <br/>
        <p>① 1er cycle (1ère-2e secondaire)</p>
        <p>② 2e cycle (3e-4e-5e secondaire)</p>
        <p>ⓒ Cégep</p>

        <br/>
        <h3>AUTRES IDÉES DE QUESTION</h3>
        <h4>Analyse stylistique</h4>
        <ul>
            <li>Quel est le registre de langue utilisé dans la chanson ? (Familier, courant, soutenu)</li>
            <li>Y a-t-il des jeux de mots, des métaphores ou des comparaisons ? Quels effets produisent-ils ?</li>
            <li>Quel est le ton de la chanson ? (Joyeux, mélancolique, ironique, etc.)</li>
        </ul>

        <h4>Analyse musicale</h4>
        <ul>
            <li>Comment la mélodie et le rythme soutiennent-ils les paroles de la chanson ?</li>
            <li>Quels instruments sont utilisés et comment contribuent-ils à l'ambiance générale ?</li>
            <li>Y a-t-il des changements de tempo ou de dynamique au cours de la chanson ? Pourquoi, à ton avis ?</li>
        </ul>

        <h4>Analyse structurelle</h4>
        <ul>
            <li>Comment est structurée la chanson ? (Couplets, refrain, pont, etc.)</li>
            <li>Y a-t-il une progression narrative dans les paroles ? Comment évolue-t-elle au fil de la chanson ?</li>
            <li>Le refrain apporte-t-il une signification différente à chaque reprise ? Si oui, comment ?</li>
        </ul>

        <h4>Analyse contextuelle</h4>
        <ul>
            <li>Quel est le contexte historique, social ou culturel de la chanson ?</li>
            <li>Que sait-on de l'artiste et de son influence sur le message de la chanson ?</li>
            <li>Comment la chanson est-elle perçue par le public francophone et international ?</li>
        </ul>

        <br/>
        <h3>ANALYSE DE LA COMPOSITION MUSICALE D’UNE CHANSON</h3>
        <h4>Analyse de la structure musicale générale</h4>
        <ul>
            <li>Comment est structurée la chanson ? (Est-elle composée de couplets, refrains, ponts, intro, outro, etc. ?)</li>
            <li>Y a-t-il une introduction musicale ? Si oui, comment prépare-t-elle l’auditeur à ce qui va suivre ?</li>
            <li>Comment les sections de la chanson (couplets, refrains, pont, etc.) sont-elles organisées ? (Exemple : ABABCB)</li>
        </ul>

        <h4>Analyse des éléments rythmiques</h4>
        <ul>
            <li>Quel est le tempo de la chanson ? Est-il rapide, modéré ou lent ? Comment contribue-t-il à l'ambiance générale ?</li>
            <li>Y a-t-il des variations de tempo au cours de la chanson ? Si oui, à quels moments et pourquoi ?</li>
            <li>Quels types de motifs rythmiques sont utilisés ? (Syncopés, répétitifs, variés, etc.)</li>
        </ul>

        <h4>Analyse de l'harmonie et de la mélodie</h4>
        <ul>
            <li>Quels accords ou progressions d'accords sont utilisés ? Sont-ils simples ou complexes ?</li>
            <li>La mélodie est-elle répétitive ou change-t-elle fréquemment ? Comment évolue-t-elle tout au long de la chanson ?</li>
            <li>Y a-t-il des modulations (changement de tonalité) dans la chanson ? Si oui, à quel moment et quel effet cela produit-il ?</li>
        </ul>

        <h4>Analyse des instruments et de l'arrangement</h4>
        <ul>
            <li>Quels instruments sont présents dans l'arrangement musical ? Comment contribuent-ils à l’atmosphère de la chanson ?</li>
            <li>Y a-t-il des solos instrumentaux ou des moments où certains instruments sont mis en avant ? Quels effets cela produit-il ?</li>
            <li>Comment les instruments sont-ils disposés dans le mixage ? (Gauche, droite, centre) Quel est l'effet de ce placement sur l'écoute ?</li>
        </ul>

        <h4>Analyse de la dynamique et des effets sonores</h4>
        <ul>
            <li>Comment la dynamique change-t-elle au cours de la chanson ? (Crescendos, diminuendos, etc.)</li>
            <li>Quels effets sonores ou techniques de production (réverbération, delay, distorsion, etc.) sont utilisés ? Comment influencent-ils la perception de la chanson ?</li>
            <li>Y a-t-il des silences ou des pauses significatives dans la musique ? Quel effet ces moments de silence ont-ils sur l'écoute ?</li>
        </ul>

        <h4>Analyse de la relation entre la musique et les paroles</h4>
        <ul>
            <li>Comment la musique soutient-elle ou contraste-t-elle avec le texte des paroles ?</li>
            <li>Y a-t-il des moments où la musique souligne particulièrement certaines phrases ou mots clés des paroles ?</li>
            <li>La structure musicale reflète-t-elle une progression ou une répétition dans le thème des paroles ?</li>
        </ul>

        <div className='text-center mt-16 transform transition-transform hover:scale-105 duration-200 scale-100'>
            <a className='bg-blue-school text-white rounded-2xl p-4 text-2xl font-ohno'
            href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZvQacLVsGrYSv9xZ_Analysedechanson-secondaire.pdf"
            target="_blank"
            rel="noreferrer noopener">Imprimer cette page</a>
        </div>
    </div>
)

const AnalysePrimaire = () => (
    <div>
        <h3 className='text-center !no-underline !text-[32px]'>Exemple d'activité pour le primaire</h3>
        <ul>
            <li>Dictée avec les paroles d’une chanson</li>
            <li>Discussion en classe autour de la performance donnée par l’artiste</li>
            <li>Approfondissement d’une chanson de l’artiste, voir question ci-bas:</li>
        </ul>

        <h3>Thèmes et Style</h3>
        <ul>
            <li>D’après toi, de quoi la chanson parle-t-elle? ① ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : Parle-t-elle de l'amitié, d’amour, de voyages, etc.? </li>
                    <li>① Astuce: Donner des choix de réponses en fonction de la chanson choisie</li>
                </ul>
            </li>
            <li>Comment te fait sentir la chanson? ① ②</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : Est-ce que ça te rend heureux, triste, excité?</li>
                </ul>
            </li>
            <li>Y a-t-il des moments dans la chanson qui te font rire ou pleurer? Pourquoi? ① ② ③</li>
            <li>As-tu une idée du style de la chanson? ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : Pop, country, rock, etc.?</li>
                </ul>
            </li>
        </ul>

        <h3>Musique</h3>
        <ul>
            <li>Comment est le rythme de la chanson? ① ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : Est-il rapide, lent, entraînant?</li>
                    <li>① Astuce: Donner des exemples de rythme en clappant des mains</li>
                </ul>
            </li>

            <li>Quels instruments entends-tu dans la chanson? ① ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : De la guitare, du piano, des percussions?</li>
                </ul>
            </li>
            <li>Est-ce qu’il y a des instruments que tu ne connais pas ou qui sortent de l’ordinaire? ① ② ③</li>
        </ul>

        <h3>Paroles</h3>
        <ul>
            <li>Quels sont les mots ou expressions que tu trouves intéressants ou difficiles dans la chanson? ① ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : Y a-t-il des mots que tu n’as jamais entendus?</li>
                    <li>① Astuce: Soulever un mot ou une expression dans la chanson et demander s’ils ont déjà entendu ce mot</li>
                </ul>
            </li>
            <li>Y a-t-il des rimes dans la chanson? Peux-tu en trouver quelques exemples? ① ②</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : Les mots finissent-ils par le même son?</li>
                </ul>
            </li>
        </ul>

        <h3>Participation et création</h3>
        <ul>
            <li>Comment t'imagines-tu la chanson en dessin? Peux-tu dessiner/peindre ce à quoi la chanson te fait penser? ① ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>③ Astuce: Adapter l’activité de création en fonction du niveau scolaire</li>
                </ul>
            </li>
            <li>À quelle couleur te fait penser la chanson et les émotions que tu as ressenties en l’écoutant? ① ② ③</li>
        </ul>

        <h3>Structure musicale</h3>
        <ul>
            <li>Comment est structurée la chanson ? (Est-elle composée de couplets, refrains, ponts, intro, outro, etc.?) ③</li>
            <li>Y a-t-il une introduction musicale? Si oui, comment prépare-t-elle l’auditeur à ce qui va suivre? ③</li>
            <li>Comment les sections de la chanson (couplets, refrains, ponts, etc.) sont-elles organisées? (Exemple : ABABCB) ③</li>
            <li>Y a-t-il des jeux de mots ou des comparaisons? Quels effets produisent-ils? ③</li>
        </ul>

        <h3>Suite au spectacle</h3>
        <ul>
            <li>Quel était ton instrument préféré? Pourquoi? ① ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>① Astuce: Leur offrir des choix (C’est impressionnant! / J’aime le son! / Mon père en joue à la maison!, etc.)</li>
                </ul>
            </li>
            <li>Comment as-tu aimé l’activité? Qu’as-tu préféré? ① ② ③</li>
            <li>Serais-tu gêné de faire un spectacle devant une foule? ① ② ③</li>
            <li>Quelles émotions as-tu ressenties? ① ② ③</li>
            <li className='list-none'>
                <ul>
                    <li>Ex : de la joie, de la tristesse?</li>
                </ul>
            </li>
            <li>Qu’as-tu retenu de ta rencontre avec l’artiste? ① ② ③</li>
        </ul>

        <div className='text-center mt-16 transform transition-transform hover:scale-105 duration-200 scale-100'>
            <a className='bg-blue-school text-white rounded-2xl p-4 text-2xl font-ohno'
            href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZvQtkrVsGrYSv_Pd_Analysedechanson-primaire.pdf"
            target="_blank"
            rel="noreferrer noopener">Imprimer cette page</a>
        </div>

    </div>
)

const Questions = () => (
    <div>
        <h3 className='text-center !no-underline !text-[32px]'>Questions</h3>
        <h3>Questions sur les performances</h3>
        <ul>
            <li>Est-ce que tu es nerveux·euse avant de monter sur scène ?</li>
            <li>Quelle est ta partie préférée de chanter devant des gens ?</li>
            <li>As-tu déjà fait un spectacle dans un autre pays ? C’était comment ?</li>
            <li>Est-ce que tu préfères chanter seul·e ou avec d’autres musicien·nes ?</li>
        </ul>

        <h3>Questions sur l'inspiration et les influences</h3>
        <ul>
            <li>Qui est ton chanteur·euse ou groupe préféré ?</li>
            <li>Est-ce que tu joues de plusieurs instruments ? Lequel est ton préféré ?</li>
            <li>Y a-t-il un moment ou une personne spéciale qui t’a inspiré une chanson ?</li>
            <li>Est-ce que tu écoutes beaucoup de musique d’autres artistes ?</li>
            <li>Quels artistes ou genres musicaux ont le plus influencé ton style ?</li>
            <li>Si tu pouvais collaborer avec n’importe quel artiste, qui choisirais-tu et pourquoi ?</li>
            <li>Comment intègres-tu différentes influences culturelles ou musicales dans tes chansons ?</li>
            <li>Est-ce qu’il y a une chanson ou un album qui a changé ta vie ou ta façon de voir la musique ?</li>
        </ul>

        <h3>Questions sur la vie d’artiste</h3>
        <ul>
            <li>C’est quoi la partie la plus amusante d’être un·e auteur·e-compositeur·e-interprète ?</li>
            <li>Est-ce que c’est difficile d’écrire des chansons tous les jours ?</li>
            <li>Comment fais-tu pour rester créatif·ive ?</li>
            <li>Est-ce que tu chantes toujours ce que tu écris ou tu donnes parfois tes chansons à d’autres ?</li>
            <li>Comment équilibres-tu ta vie personnelle avec ta carrière musicale ?</li>
            <li>Est-ce que ta perception de toi-même a changé depuis que tu as commencé ta carrière musicale ?</li>
            <li>Qu'est-ce qui te motive chaque jour à continuer d’écrire et de jouer de la musique ?</li>
            <li>Si tu n'étais pas musicien·ne, que ferais-tu comme métier ?</li>
        </ul>

        <h3>Questions sur l’avenir et les conseils</h3>
        <ul>
            <li>Que dirais-tu à une personne qui veut devenir chanteur·euse ou auteur·e-compositeur·e ?</li>
            <li>As-tu des projets pour de nouvelles chansons ou albums ?</li>
            <li>Où aimerais-tu chanter que tu n’as pas encore eu la chance de faire ?</li>
            <li>Quels sont tes projets futurs dans la musique ? Un nouvel album, une tournée, des collaborations ?</li>
            <li>Comment vois-tu l’évolution de ta carrière dans les cinq ou dix prochaines années ?</li>
            <li>Quel conseil donnerais-tu à un jeune qui souhaite poursuivre une carrière dans la musique ?</li>
            <li>Qu'aimerais-tu que les gens retiennent de ton travail et de ta musique dans l’avenir ?</li>
        </ul>

        <h3>Questions sur le processus créatif et l'écriture</h3>
        <ul>
            <li>Quelles sont les principales sources d'inspiration pour tes chansons ? (Événements personnels, actualités, expériences de vie, etc.)</li>
            <li>Quel est le plus grand défi que tu rencontres en tant qu’auteur·e-compositeur·e lorsqu’il s’agit d’écrire de nouvelles chansons ?</li>
            <li>Comment gères-tu le syndrome de la page blanche ou le manque d'inspiration ?</li>
            <li>Est-ce que ton processus d'écriture a évolué au fil des années ? Si oui, comment ?</li>
        </ul>

        <h3>Questions sur la carrière et l'expérience</h3>
        <ul>
            <li>Qu'est-ce qui t'a poussé·e à devenir auteur·e-compositeur·e-interprète ?</li>
            <li>Peux-tu partager une expérience mémorable ou un moment marquant de ta carrière ?</li>
            <li>As-tu déjà eu des doutes sur ta carrière musicale ? Si oui, comment les as-tu surmontés ?</li>
            <li>Comment gères-tu le succès ou les critiques en tant qu’artiste ?</li>
        </ul>

        <h3>Questions sur la musique et la technique</h3>
        <ul>
            <li>Comment choisis-tu les instruments ou les arrangements pour une chanson ?</li>
            <li>Peux-tu expliquer comment une chanson passe de l'idée initiale à la version finale qu'on entend sur un album ?</li>
            <li>Quel rôle joue la production musicale dans ta création artistique ?</li>
            <li>As-tu une technique ou un exercice particulier pour améliorer tes compétences en écriture de chansons ou en musique ?</li>
        </ul>

        <h3>Questions sur les thèmes et les messages</h3>
        <ul>
            <li>Y a-t-il un message ou un thème récurrent que tu souhaites transmettre à travers tes chansons ?</li>
            <li>Comment tes chansons reflètent-elles ta vision du monde ou ta philosophie personnelle ?</li>
            <li>Est-ce que tu penses que la musique peut avoir un impact social ou politique ? Si oui, comment ?</li>
            <li>Y a-t-il des sujets ou des thèmes que tu trouves difficiles à aborder dans tes chansons ?</li>
        </ul>

        <div className='text-center mt-16 transform transition-transform hover:scale-105 duration-200 scale-100'>
            <a className='bg-blue-school text-white rounded-2xl p-4 text-2xl font-ohno'
            href="https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZvQt6bVsGrYSv_QD_Questionspourl%27artiste.pdf"
            target="_blank"
            rel="noreferrer noopener">Imprimer cette page</a>
        </div>

    </div>
)

const SchoolActivites = () => {
  const [activeComponent, setActiveComponent] = useState(null)
  const handleShowAnalysePrimaire = () => { setActiveComponent('Primaire') }
  const handleShowAnalyseSecondaire = () => { setActiveComponent('Secondaire') }
  const handleShowQuestions = () => { setActiveComponent('Questions') }

  return (
    <SchoolLayout>
        <div className='school-preparation'>

            <SchoolInfosNavigation />

            <h2>Activités</h2>

            <div className='school-preparation-inner'>

                <div className='flex text-center w-full mx-auto'>
                    <div className='w-1/2 bg-white rounded-3xl md:mx-4 mx-0'>
                        <h2 className='text-center !mx-0 !px-0 !text-blue-school h-24 my-6'>Primaire</h2>
                        <div className='!text-center flex mx-auto w-full justify-center items-center'>
                            <BigButton text="Analyse" largeText={false} className="mx-auto md:mx-1" innerClassName="!bg-blue-school !text-white" onClick={handleShowAnalysePrimaire } />
                            <BigButton text="Questions" largeText={false} className="mx-auto md:mx-1" innerClassName="!bg-blue-school !text-white" onClick={handleShowQuestions} />
                        </div>
                    </div>
                    <div className='w-1/2 bg-white rounded-3xl md:mx-4 mx-0'>
                        <h2 className='text-center !mx-0 !px-0 !text-blue-school h-36'>Secondaire et cégep</h2>
                        <div className='!text-center flex mx-auto w-full justify-center items-center'>
                            <BigButton text="Analyse" largeText={false} className="mx-auto md:mx-1" innerClassName="!bg-blue-school !text-white" onClick={handleShowAnalyseSecondaire} />
                            <BigButton text="Questions" largeText={false} className="mx-auto md:mx-1" innerClassName="!bg-blue-school !text-white" onClick={handleShowQuestions} />
                        </div>
                    </div>
                </div>

                <div className='mt-9'>
                    {activeComponent === 'Secondaire' && <AnalyseSecondaire />}
                    {activeComponent === 'Primaire' && <AnalysePrimaire />}
                    {activeComponent === 'Questions' && <Questions />}
                </div>
            </div>

        </div>
    </SchoolLayout>
  )
}

export default SchoolActivites
